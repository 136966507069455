import React from 'react';
import './Login.css';
import { ReactComponent as FB } from '../assets/images/FB.svg';
import { ReactComponent as LN } from '../assets/images/LN.svg';
import { ReactComponent as Insta } from '../assets/images/Insta.svg';
import { ReactComponent as LogoWord } from '../assets/images/Logo_Word.svg';
import hmacSHA512 from 'crypto-js/hmac-sha512';
import Base64 from 'crypto-js/enc-base64';
import Cookies from 'universal-cookie';
import MobileHeader from '../Components/MobileHeader/MobileHeader.js';
import MobileFooter from '../Components/MobileFooter/MobileFooter';
const cookies = new Cookies();

export default class Login extends React.Component {
  constructor(props){
    super(props);
    this.state={
      email:'',
      password:'',
      captcha:false,
      id:'',
      sidebar: true,
    }
  }
  
  submit = async ()=>{
    const password = this.state.password;
    var digest = Base64.stringify(hmacSHA512(this.state.email,password));
    const url = 'http://192.168.2.10:8080/AH_mini_deployed/Login?email='+this.state.email+'&hash='+digest;
    console.log(url);
    var response = await fetch(url);
    response.json().then(
        async (data)=>{
            if(data.Response.error){
              this.setState({errorMessage:data.Response.errorMessage});
            }else{
              this.setState({id:data.Response.id,errorMessage:''});
              cookies.set('addinghelp_userId', data.Response.id,{ path: '/', sameSite:'strict', secure:false});
              this.props.history.push('/DashboardHome');
            }
        }
    )
  }
  render(){
    
    return (
      <div className="ScreenContainerLogin">
      { this.state.sidebar ?
        <div className="LeftContainer">
        <div className="LogoContainer">
          <LogoWord style={{width:'220px', height: '220px'}} />
        </div>
    
        <div className="TextContainer">
          <p className="Text_Title">Join the vision towards Automated Social Impact</p>
          <p className="Text_Paragraph" >Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex </p>
        </div>
        <div className="SocialsContainer">
          <div className="SocialsIconContainer">
            <FB className="SocialsIcon" />
            <Insta className="SocialsIcon"/>
            <LN className="SocialsIcon"/>
          </div>
        </div>
    
        <div className="FooterContainer">
          <div className="FooterText">Privacy Policy</div>
          <div className="FooterText">Contact</div>
          <div className="FooterText">©Addinghelp. B.V. 2020</div>
        </div>
        </div>
        : 
        <MobileHeader/>
        
        }
        <div className="RightContainerLogin">

        <div className="TitleContainerLogin">
          <p className="Text_WelcomeLogin">Welcome to addinghelp.</p>
          <p className="Text_Error">{this.state.errorMessage}</p>
        </div>
    
        <div className="FormContainerLogin">
         <form className="FormLogin">
            <div className="FormRowLogin">
              <div className="FormFieldLogin">
              <label className="FormFieldLabelLogin">Email</label>
              <input
                type="email" 
                className="FormFieldInputLogin"
                placeholder="example@example.com"
                name='email'
                maxLength={100}
                onChange={(value, data)=>{
                  var id = document.getElementsByName('email');
                  this.setState({email:id[0].value});
                  console.log(this.state.email)
                  }
                }
                onKeyDown={
                    (e)=>{
                      if(e.key === 'Enter'){
                          console.log(this.state.email);
                      }
                    }
                }
              />
            </div>
            </div>
    
            <div className="FormRowLogin">
              <div className="FormFieldLogin">
              <label className="FormFieldLabelLogin">Password</label>
              <input type="password" className="FormFieldInputLogin" placeholder=""
                name='password'
                maxLength={30}
                onChange={(value, data)=>{
                  var id = document.getElementsByName('password');
                  this.setState({password:id[0].value});
                  console.log(this.state.password)
                  }
                }
                onKeyDown={
                  (e)=>{
                    if(e.key === 'Enter'){
                        console.log(this.state.password);
                    }
                  }
                }
              />
            </div>
            </div>
            <a className="Text_Forgot" href='/PasswordRecovery'>Forgot your password?</a>
         </form>
         </div>
         <div className="ButtonContainerLogin">
          <button className="SignButtonLogin" onClick={this.submit}>
            Log In
          </button>
          <p className="Text_Dont">Don't have an account? Click here to <a className="Text_Href" href='/SignUp'>Sign Up</a></p>
         </div>
        </div>
        { this.state.sidebar ?
        null : 
        <MobileFooter/>
        
        }
      </div>
    );
  }
}

