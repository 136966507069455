import React from 'react';
import { ReactComponent as Logo } from '../../assets/images/Logo.svg';
import './MobileHeader.css';


export default class MobileHeader extends React.Component{
    constructor(props){
        super(props);
        this.state={
          sidebar: false,
        }
      }
    render(){
        return (
            <div  className="MobileHeader">
            <Logo style={{ width: '50px', height: '50px'}}/>
            </div>
            
        )}
}

