import React from 'react';
import './MobileFooter.css';
import { ReactComponent as FB } from '../../assets/images/FB.svg';
import { ReactComponent as LN } from '../../assets/images/LN.svg';
import { ReactComponent as Insta } from '../../assets/images/Insta.svg';


export default class MobileFooter extends React.Component{
    render(){
        return (
             <div className="MobileFooter">
                <div className="FooterContainerMobile">
                <p className="Text_TitleMobile">Join the vision towards Automated Social Impact</p>

                </div>
                <div className="SocialsContainerMobile">
                <div className="SocialsIconContainerMobile">
                    <FB className="SocialsIconMobile" />
                    <Insta className="SocialsIconMobile"/>
                    <LN className="SocialsIconMobile"/>
                </div>
                <div style={{paddingTop: '30px', height: '200px', display: 'flex', flexDirection: 'column', justifyContent: 'space-around '}} >
                <div className="FooterTextMobile">Terms & Conditions</div>
                <div className="FooterTextMobile">Privacy Policy</div>
                <div className="FooterTextMobile">Contact</div>
                <div className="FooterTextMobile">©Addinghelp. B.V. 2020</div>
                </div>
                </div>
                </div>
        )}
}



