import React from 'react';
import './MessagePage.css';
import { ReactComponent as FB } from '../assets/images/FB.svg';
import { ReactComponent as LN } from '../assets/images/LN.svg';
import { ReactComponent as Insta } from '../assets/images/Insta.svg';
import { ReactComponent as LogoWord } from '../assets/images/Logo_Word.svg';
import Checker from '../assets/images/CheckMark.png';
import MobileHeader from '../Components/MobileHeader/MobileHeader';
import MobileFooter from '../Components/MobileFooter/MobileFooter';



export default class MessagePage extends React.Component {
  constructor(props){
    super(props);
    this.state ={

      sidebar: true,

    }
  }
  render(){

    return (
      <div className="ScreenContainerPage">
      { this.state.sidebar ?
         <div className="LeftContainer">
        <div className="LogoContainer">
          <LogoWord  style={{width:'220px', height: '220px'}} />
        </div>
    
        <div className="TextContainer">
          <p className="Text_Title">Join the vision towards Automated Social Impact</p>
          <p className="Text_Paragraph" >Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex </p>
        </div>
        <div className="SocialsContainer">
          <div className="SocialsIconContainer">
            <FB className="SocialsIcon" />
            <Insta className="SocialsIcon"/>
            <LN className="SocialsIcon"/>
          </div>
        </div>
        <div className="FooterContainer">
          <div className="FooterText">Privacy Policy</div>
          <div className="FooterText">Contact</div>
          <div className="FooterText">©Addinghelp. B.V. 2020</div>
        </div>
        </div>
        : 
        <MobileHeader/>
      }
        <div className="RightContainerPage">
        <img src={Checker} alt='' style={{width:'90px', height: '90px'}}/>
        <div className="TitleContainerPage">
          <text className="Text_MessagePage">An email has been sent to you with a recovery link</text>
        </div>
         <div className="ButtonContainerPage">
          <button className="SignButton" onClick={()=>{this.props.history.push('/')}}>
            Home
          </button>
         </div>
        </div>

        { this.state.sidebar ? 
        null 
        :
        <MobileFooter/>
        }
      </div>
    );
  }
}