import React from 'react';
import './PasswordRecovery.css';
import { ReactComponent as FB } from '../assets/images/FB.svg';
import { ReactComponent as LN } from '../assets/images/LN.svg';
import { ReactComponent as Insta } from '../assets/images/Insta.svg';
import { ReactComponent as LogoWord } from '../assets/images/Logo_Word.svg';
import MobileHeader from '../Components/MobileHeader/MobileHeader';
import MobileFooter from '../Components/MobileFooter/MobileFooter';

export default class PasswordRecovery extends React.Component{
  constructor(props){
    super(props);
    this.state={
      email:'',
      errorMessage:'',
      sidebar: true,
    }
  }

  submit = async ()=>{
    if(this.state.email.length >= 6){
      const url = 'http://192.168.2.10:8080/AH_mini_deployed/RecoverPassword?email='+this.state.email;
      console.log(url);
      var response = await fetch(url);
      response.json().then(
          (data)=>{
            console.log(data)
            if(data.Response){
              this.props.history.push('/MessagePage');
            }else{
              this.setState({errorMessage:'Something went wrong, check if you entered your email correctly.'});
            }
          }
      )
    }else{
      this.setState({errorMessage:'Please fill in a valid email address.'})
    }
  }
  render(){

    return (
      <div className="ScreenContainerRecovery">
       { this.state.sidebar ?
         <div className="LeftContainer">
        <div className="LogoContainer">
          <LogoWord style={{width:'220px', height: '220px'}} />
        </div>
        <div className="TextContainer">
          <p className="Text_Title">Join the vision towards Automated Social Impact</p>
          <p className="Text_Paragraph" >Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex </p>
        </div>
        <div className="SocialsContainer">
          <div className="SocialsIconContainer">
            <FB className="SocialsIcon" />
            <Insta className="SocialsIcon"/>
            <LN className="SocialsIcon"/>
          </div>
        </div>
    
        <div className="FooterContainer">
          <div className="FooterText">Privacy Policy</div>
          <div className="FooterText">Contact</div>
          <div className="FooterText">©Addinghelp. B.V. 2020</div>
        </div>
        </div>

        : 
        <MobileHeader/>
        
        }
    
        <div className="RightContainerRecovery">
    
        <div className="TitleContainerRecovery">
          <text className="Text_Error">{this.state.errorMessage}</text>
          <text className="Text_RightTitleRecovery">Password recovery</text>
        </div>
    
        <div className="FormContainerRecovery">
         <form className="FormRecovery">
            <div className="FormRowRecovery">
              <div className="FormFieldRecovery">
              <label className="FormFieldLabelRecovery">Fill in your email</label>
              <input
                type="email" 
                className="FormFieldInputRecovery"
                placeholder="example@example.com"
                name='email'
                maxLength={100}
                onChange={(value, data)=>{
                  var id = document.getElementsByName('email');
                  this.setState({email:id[0].value});
                  console.log(this.state.email)
                  }
                }
                onKeyDown={
                    (e)=>{
                      if(e.key === 'Enter'){
                          console.log(this.state.email);
                      }
                    }
                }
              />
            </div>
            </div>
         </form>
         </div>
    
         <div className="ButtonContainerRecovery">
          <button className="SignButton" onClick={this.submit}>
            Recover
          </button>
         </div>
    
        </div>

        { this.state.sidebar ? null :
        <MobileFooter/>}

      </div>
    );
  }
}