import React from 'react';
import './DashboardHome.css';
import { ReactComponent as FB } from '../assets/images/FB.svg';
import { ReactComponent as LN } from '../assets/images/LN.svg';
import { ReactComponent as Insta } from '../assets/images/Insta.svg';
import { ReactComponent as Logo} from '../assets/images/Logo.svg';
import Cookies from 'universal-cookie';
import MobileHeader from '../Components/MobileHeader/MobileHeader';
import MobileFooter from '../Components/MobileFooter/MobileFooter';
const cookies = new Cookies();

export default class DashboardHome extends React.Component {
  constructor(props){
    super(props);
    this.state={
      userId:'',
      sidebar: true,
    }
  }

  componentDidMount(){
    const userId = cookies.get('addinghelp_userId');
    this.setState({userId:userId});
  }

  logout = ()=>{
    cookies.remove('addinghelp_userId');
    this.props.history.push('/');
  }

  render(){
    return (
      <div className="ScreenContainerDash">
      { this.state.sidebar ?
        <div className="LeftContainerDash">
    
        <div className="LogoContainerDash">
          <Logo style={{width: '80px', height: '80px'}}/>
        </div>
    
        <div className="TextContainerDash">
          <p className="Text_NameDash" >Hi Shair</p>
          <p className="Text_WelcomeDash">Welcome to Your Helpspace.</p>
          <p className="Text_ParagraphDash">Here you will be able to support projects all over the world and manage your helpcoins</p>
        </div>
        <div className="ButtonContainerDash">
          <button className="SignButtonDash" onClick={this.logout}>
            Log Out
          </button>
         </div>
        <div className="SocialsContainerDash">
    
          <div className="SocialsIconContainerDash">
    
            <FB className="SocialsIconDash" />
            <Insta className="SocialsIconDash"/>
            <LN className="SocialsIconDash"/>
    
          </div>
    
        </div>
    
        <div className="FooterContainerDash">
          <div className="FooterTextDash">Privacy Policy</div>
          <div className="FooterTextDash">Contact</div>
          <div className="FooterTextDash">©Addinghelp. B.V. 2020</div>
        </div>
    
    
        </div>
        : 
        <MobileHeader/>
        }
    
        <div className="RightContainerDash">
        <div style={{width:'100%', height:'100%', background: 'rgb(240,240,222)'}} />
        </div>

        { this.state.sidebar ? null :
        <MobileFooter/> }
      </div>
    );
  }
}
