import React from 'react';
import './SignUp.css';

export default class AccountActivation extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            info:'Verifying account ...',
            type:'Loading',
        }
    }

    componentDidMount(){
        this.activateAccount();
    }

    activateAccount = async ()=>{
        var data = this.props.location.search.split('=')[1];
        const adress = 'http://192.168.2.10:8080';
        const url = adress+'/AH_mini_deployed/VerifyAccount?test=hello&data='+data;
        var response = await fetch(url);
        response.json().then(
            (data)=>{
                console.log(data);
                if(data.Response === true){
                  console.log('Success');
                  this.setState({info:'Your account has been verified.',type:'Succes'})
                }else{
                    this.setState({info:'Something went wrong. Try logging in first, if that fails please contact our support.',type:'Failed'})
                }
            }
        )
    }
    render(){
        return(
        <div style={{color:'black'}}>{this.state.type === 'Loading'?
            'Please wait ...':
            this.state.type === 'Failed'?
            <div style={{color:'red'}}>
                Failed
            </div>:
            <div style={{color:'green'}}>
                Succes<br/>
                {this.state.info}
            </div>
        }</div>
        );
    }
}